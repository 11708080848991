import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home"
import "./App.css";
import About from "./components/About";
import Services from "./components/Services";
import Body from "./components/Body"
import Partners from "./components/Partners";
import Expertise from "./components/Expertise";
import Connect from "./components/Connect";
import { Navigate } from "react-router-dom";

function App() {

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Body />,
      children: [
        {
          path: "/",
          element: <Navigate to="/home" replace={true} />,
        },
        { path: "/home", element: <Home /> },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/about/expertise",
          element: <Expertise />,
        },
        {
          path: "/about/partners",
          element: <Partners />,
        },
        {
          path: "/services",
          element: <Services />,
        },
        {
          path: "/connect",
          element: <Connect />,
        },
      ],
    },
  ]);


  return (
    <>
    
      
      <RouterProvider router={appRouter} />
    </>
  );
}

export default App;
