import React from "react";
import { Outlet } from "react-router";
import Nav from "./Nav"
import Footer from "./Footer"

import Home from "./Home";


const Body = () => {
  return (
    <>
    <Nav/>
        <Outlet />
     <Footer/>
      </>
  );
};

export default Body;
