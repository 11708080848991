
  import React from "react";
  import AboutExpertise from "./AboutExpertise"

const Experties = () => {
  const services = [
    "Civil Litigation",
    "Criminal Litigation",
    "Commercial Litigation",
    "Property Disputes",
    "Family Law",
    "Matrimonial Disputes",
    "Co-operative Society Disputes",
    "Contract Drafting",
    "Legal Advisory",
    "Arbitration",
    "Writ Petitions",
    "Taxation",
    "Land Acquisition",
    "Intellectual Property Rights",
    "Consumer Cases",
    "NRI Legal Services",
    "Pro Bono Legal Services (Free Legal Aid to the Poor and Needy)",
  ];

  return (
    <div className="max-w-[1920px]">
      <div className="p-6   max-w-6xl mx-auto mt-8 px-4 text-gray-800 bg-stone-100">
        <h1 className="text-2xl font-bold mb-4">Our Services</h1>
        <ul className="list-disc list-inside space-y-2">
          {services.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </div>
      <AboutExpertise />
    </div>
  );
    
   
};

export default Experties;


