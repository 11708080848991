import React from "react";

const Connect = () => {
  return (
    <div className="max-w-[1920px]">
      <div
        className="p-6   max-w-6xl mx-auto mt-8 px-4 text-gray-800 bg-stone-100 font-times"
        style={{ textAlign: "justify" }}
      >
        <h2 className="text-2xl font-bold mb-4">Connect with Us</h2>
        <p className="mb-6">
          At <strong>GP LEGAL SOLUTIONS</strong>, we are always on the lookout
          for talented and driven individuals to join our team. Whether you are
          an experienced legal professional or a law student eager to gain
          valuable experience, we'd love to hear from you.
        </p>

        <h3 className="text-xl font-semibold mb-2">Join Our Team</h3>

        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-1">Join as an Associate</h4>
          <ul className="list-disc pl-5">
            <li>For experienced legal professionals seeking new challenges</li>
            <li>
              Opportunity to work on diverse cases and serve a variety of
              clients
            </li>
            <li>Collaborative and dynamic work environment</li>
            <li>Competitive salary and benefits package</li>
          </ul>
        </div>

        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-1">Join as an Intern</h4>
          <ul className="list-disc pl-5">
            <li>For law students seeking practical experience</li>
            <li>Gain insight into legal practices and procedures</li>
            <li>Work alongside seasoned advocates and lawyers</li>
            <li>Enhance your skills and expand your professional network</li>
          </ul>
        </div>

        <h3 className="text-xl font-semibold mb-2">Get in Touch</h3>
        <p className="mb-4">
          To explore opportunities with us, please submit your:
        </p>
        <ul className="list-disc pl-5 mb-6">
          <li>Resume</li>
          <li>Cover Letter</li>
          <li>Contact Information</li>
        </ul>
        <p className="mb-2">
          <strong>Email:</strong>{" "}
          <a href="mailto:gplegalsolution@gmail.com">
            gplegalsolution@gmail.com
          </a>
        </p>
        <p>
          <strong>Phone:</strong>
          <a href="tel:+9213117139 "> 9213117139</a>
          <a href="tel:+9015842284 ">, 9015842284</a>
        </p>
        <p>We look forward to connecting with you!</p>
      </div>
    </div>
  );
};

export default Connect;
