import React, { useState, useEffect } from "react";
import { images } from "../utils/constant";

const image = images


const Home = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === image.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="max-w-[1920px]">
        <div className="max-w-6xl mx-auto mt-8 px-4 text-gray-800  bg-stone-100">
          <div className="relative overflow-hidden w-full h-[40rem] customMargin:h-[30rem] rounded mb-10">
            {images.map((image, idx) => (
              <div
                key={idx}
                className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
                  idx === index ? "opacity-100" : "opacity-0"
                }`}
              >
                <img
                  src={image}
                  alt={`Image ${idx}`}
                  className="object-fill h-[40rem] customMargin:h-[30rem] w-full"
                />
              </div>
            ))}
          </div>
          <p
            className="text-2xl mb-4  font-times custom:text-none customMargin:text-none textsize:text-none italic "
            style={{ textAlign: "justify" }}
          >
            <strong>Welcome to G&P Legal Solutions</strong>, a reputable law
            firm established in 2002, dedicated to delivering expert legal
            services in civil property disputes, taxation laws, and a wide range
            of special laws. Our dedicated team of counsels possesses expertise
            in various domains of law, including:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li>
              Civil Laws [Suits of all categories, with specialization in
              Property Disputes and Recovery Suits before District Courts and
              High Courts (both Original and Appellate Jurisdiction of High
              Court)]
            </li>
            <br />
            <li>Writ Civil</li>
            <li>Criminal Writs</li>
            <li>White Collar Crime</li>
            <li>
              Commercial Laws
              <ul className="list-disc pl-6 mt-2">
                <li>Commercial Courts Act</li>
                <li>Arbitration and Conciliation Act</li>
                <li>Contract Act</li>
                <li>Company Law</li>
                <li>Insolvency and Bankruptcy Code</li>
              </ul>
            </li>
            <br />
            <li>POCSO Act</li>
            <li>NDPS Act</li>
            <li>NCB Cases</li>
            <li>CBI Cases</li>
            <li>NIA Cases (Section 138)</li>
            <li>
              Property Attachment Cases under:
              <ul className="list-disc pl-6 mt-2">
                <li>SAFEMA</li>
                <li>FEMA</li>
              </ul>
            </li>
          </ul>
          <p className="mb-6 font-semibold italic text-2xl font-times">
            We have a strong presence in various courts across India, Including:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li>Supreme Court of India</li>
            <li>Delhi High Court (all jurisdictions)</li>
            <li>All District Courts of Delhi</li>
            <li>Allahabad High Court (Lucknow Bench)</li>
            <li>Punjab and Haryana High Court</li>
            <li>RERA (Real Estate Regulatory Authority)</li>
            <li>Armed Forces Tribunal</li>
            <li>Armed Forces Appellate Tribunal</li>
            <li>Central Administrative Tribunal</li>
            <li>Administrative Tribunals of other states</li>
            <li>Consumer Forums</li>
          </ul>
          <p className="mb-6  font-sarif" style={{ textAlign: "justify" }}>
            Our experienced team provides strategic legal guidance and
            representation to individuals, businesses, and organizations,
            ensuring effective solutions and favorable outcomes. At g&p Legal
            Solutions, we remain committed to our founding principles of
            excellence, integrity, and personalized service, ensuring our
            clients receive the highest quality legal support.
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;

