
  import React from "react";
  import { services } from "../utils/constant";

const Services = () => {
  

  return (
    <div className="max-w-[1920px]">
      <div
        className="p-6  max-w-6xl mx-auto mt-8 px-4 text-gray-800 bg-stone-100 font-times "
        style={{ textAlign: "justify" }}
      >
        <h1 className="text-3xl font-bold mb-6 italic">
          G&P Legal Solutions: Your Trusted Legal Partner
        </h1>
        <p className="mb-4" style={{ textAlign: "justify" }}>
          G&P Legal Solutions is a full-service law firm offering a wide range
          of legal services to individuals, businesses, and organizations. Our
          team of experienced advocates and legal professionals provides expert
          legal guidance and representation across various courts and forums.
        </p>
        <h2 className="text-2xl font-bold mb-4">Our Services:</h2>
        <ul className="list-disc pl-6">
          {services.map((service, index) => {
            const [boldPart, normalPart] = service.split(":"); // Split at the colon
            return (
              <li key={index} className="mb-2">
                <strong>{boldPart}:</strong>
                {normalPart && <span>{normalPart}</span>}
              </li>
            );
          })}
        </ul>

        <p className="mt-6">Contact us today to avail of our legal services!</p>
      </div>
    </div>
  );
};

export default Services;

