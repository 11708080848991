import React, { useState, useEffect } from "react";
import { aboutImages } from "../utils/constant";

const About = () => {
  const [index, setIndex] = useState(0);
  const corosal = aboutImages;

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === corosal.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="max-w-[1920px]">
      <div className="max-w-[70rem] mx-auto mt-8 px-4 mb-24 text-gray-800 p-6 bg-stone-100 font-times">
        <div className="relative overflow-hidden w-full h-[40rem] customMargin:h-[30rem] mb-10 rounded">
          {corosal.map((img, idx) => (
            <div
              key={idx}
              className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
                idx === index ? "opacity-100" : "opacity-0"
              }`}
            >
              <img
                src={img}
                alt={`Image ${idx}`}
                className="object-fill h-[40rem] customMargin:h-[30rem] w-full"
              />
            </div>
          ))}
        </div>
        <p
          className="text-lg mb-4  font-sarif tracking-wide "
          style={{ textAlign: "justify" }}
        >
          G&P Legal Solutions, a reputed law firm, was established in the year
          2002 with a vision to provide comprehensive legal services to
          individuals, businesses, and organizations. With over two decades of
          experience, our team of skilled advocates and legal professionals has
          been delivering expert legal guidance and representation across
          various courts and forums.
        </p>

        <p
          className="text-lg mb-4  font-sarif tracking-wide mt-8"
          style={{ textAlign: "justify" }}
        >
          Our firm's founding partner, Advocate Pramod Kumar Tripathi, a
          seasoned legal professional with over 22 years of experience, has
          built a strong reputation for providing quality legal services. Under
          his leadership, G&P Legal Solutions has grown into a full-service law
          firm, offering a wide range of legal services, including civil
          litigation, criminal litigation, commercial litigation, property
          disputes, family law, wills & probate, contract drafting, legal
          advisory, arbitration, taxation, co-operative society matters, real
          estate transactions, intellectual property rights, consumer cases, and
          NRI legal services
        </p>
        <p
          className="text-lg mb-4  font-sarif tracking-wide mt-8"
          style={{ textAlign: "justify" }}
        >
          With a network spread across Delhi, our firm has a strong presence in
          all corners of the city, making us easily accessible to clients from
          all over Delhi. Client satisfaction is the topmost priority of our
          firm, and we strive to provide personalized attention, prompt service,
          and sound legal advice to ensure that our clients' legal needs are met
          with the highest level of expertise and dedication. Our commitment to
          excellence has earned us a loyal client base and a reputation for
          excellence in the legal community. We take pride in our ability to
          understand our clients' needs and provide tailored solutions that meet
          their unique requirements.
        </p>
        <p
          className="text-lg mb-4  font-sarif tracking-wide mt-8"
          style={{ textAlign: "justify" }}
        >
          Our commitment to excellence has earned us a loyal client base and a
          reputation for excellence in the legal community. We take pride in our
          ability to understand our clients' needs and provide tailored
          solutions that meet their unique requirements.
        </p>
      </div>
    </div>
  );
};

export default About;
