import React from "react";

const Footer = () => {
  return (
    <div className="w-full h-auto py-4 border-solid border border-black bg-stone-600 text-white flex flex-col md:flex-row md:justify-around max-w-[1920px] font-times mt-10">
      <div className="mb-6">
        <p className="mb-2 text-center md:text-left">Useful Links:</p>
        <ul className="list-disc pl-6">
          <li className="flex items-start mb-2 ">
            <i className="fas fa-link text-white-500 mr-2 mt-1"></i>
            <a
              className="text-white ml-2 hover:underline"
              href="https://www.sci.gov.in/"
            >
              Supreme Court of India
            </a>
          </li>
          <li className="flex items-start mb-2">
            <i className="fas fa-link text-white-500 mr-2 mt-1"></i>
            <a
              className="text-white ml-2 hover:underline"
              href="https://delhihighcourt.nic.in/"
            >
              Delhi High Court:
            </a>
          </li>
          <li className="flex items-start mb-2">
            <i className="fas fa-link text-white-500 mr-2 mt-1"></i>
            <a
              className="text-white ml-2 hover:underline"
              href="https://districts.ecourts.gov.in/delhi"
            >
              E-Courts Delhi:
            </a>
          </li>
          <li className="flex items-start mb-2">
            <i className="fas fa-link text-white-500 mr-2 mt-1"></i>
            <a
              className="text-white ml-2 hover:underline"
              href="https://atfp.gov.in/about.html"
            >
              SAFEMA
            </a>
          </li>
          <li className="flex items-start mb-2">
            <i className="fas fa-link text-white-500 mr-2 mt-1"></i>
            <a
              className="text-white hover:underline ml-2"
              href="https://confonet.nic.in/"
            >
              Confonet
            </a>
          </li>
        </ul>
      </div>
      <div className="mb-6">
        <p className="font-bold text-center md:text-left">Contact Us:</p>
        <ul className="list-disc pl-6">
          <li className="px-0">
            Address:
            <br />
            693, Creative Heights, Sector-29 Rohini, Delhi-110032
            <li className="list-none">Chamber No. 674 B, Western Wing,</li>
            <li className="list-none">Tis Hazari Courts, Delhi - 110054</li>
            <li className="list-none">NCR Office: 2506, Tower C,</li>
            <li className="list-none">
              Ratan Pearls, Sec - 16, Greater Noida West, Uttar Pradesh
            </li>
          </li>
          <li>
            Mobile No:
            <a href="tel:+9213117139 "> 9213117139</a>
            <a href="tel:+9015842284 ">, 9015842284</a>
          </li>
          <li>
            <a href="mailto:gplegalsolution@gmail.com">
              Email: gplegalsolution@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
