import React from "react";

const Partners = () => {
  return (
    <div className="w-full justify-items-center  grid grid-cols-1 md:grid-cols-2 gap-6 max-w-[1920px]">
      <div className="flex-1 flex flex-col">
        <img
          src="/partnersImage/Pramod Kumar Tripathi.jpg"
          className="w-1/2 mx-auto "
        />

        <div className="p-6 max-w-4xl mx-auto h-full  shadow-md rounded-lg  bg-stone-100 font-times">
          <h2
            className="text-3xl font-bold mb-4 flex flex-col justify-center items-center"
            style={{ textAlign: "center" }}
          >
            Pramod Kumar Tripathi
          </h2>

          <p className="mb-4">
            With over 22 years of experience, Advocate Pramod Kumar Tripathi is
            a seasoned legal expert and a key partner at G&P Legal Solutions.
            Enrolled with the Bar Council of Delhi in 2002, Mr. Tripathi began
            his legal journey under the mentorship of retired Judge RS Mahela.
            His extensive experience encompasses both criminal and civil law,
            and he has developed a strong expertise in various domains.
          </p>

          <h4 className="text-xl font-semibold mb-2 ">Specializations:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>
              <strong>Local Delhi Laws:</strong> Delhi Rent Control Act, MCD
              Act, Electricity Act
            </li>
            <li>
              <strong>Co-operative Society Cases:</strong> Registrar of
              Co-operative Societies, Delhi High Court
            </li>
          </ul>

          <h4 className="text-xl font-semibold mb-2 ">Memberships:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Supreme Court Bar Association</li>
            <li>Delhi High Court Bar Association</li>
            <li>Founder Member, Central Delhi Court Bar Association</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2 ">
            Tribunals and Forums Represented:
          </h4>
          <ul className="list-disc ml-6 mb-4">
            <li>SAFEMA Tribunal</li>
            <li>Central Administrative Tribunal</li>
            <li>RERA Tribunal</li>
            <li>RERA Appellate Tribunal</li>
            <li>Consumer Forums</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2 ">Expertise:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>POCSO Cases</li>
            <li>NDPS Cases</li>
            <li>Civil Property Disputes</li>
            <li>RERA Matters</li>
            <li>Consumer Matters</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2 ">Appointments:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Local Commissioner by the Hon'ble Courts of Delhi</li>
          </ul>

          <p>
            Advocate Tripathi has successfully represented clients across Delhi,
            the Allahabad High Court, and the Punjab and Haryana High Court. His
            extensive practice and in-depth knowledge make him an invaluable
            asset to G&P Legal Solutions.
          </p>
        </div>
      </div>
      <div className=" flex-1 flex flex-col">
        <img src="/partnersImage/Gulab Singh.jpg" className="w-1/2 mx-auto" />

        <div className="p-6 max-w-4xl mx-auto h-full shadow-md rounded-lg bg-stone-100 font-times">
          <h2
            className="text-3xl font-bold mb-4 "
            style={{ textAlign: "center" }}
          >
            Gulab Singh
          </h2>
          <p className="mb-4">
            Advocate Gulab Singh is a seasoned criminal law expert with a proven
            track record of delivering exceptional results in complex criminal
            cases. With with more than 20 years of experience, he has
            established himself as a formidable advocate with a deep
            understanding of criminal laws and procedures.
          </p>

          <h4 className="text-xl font-semibold mb-2">Expertise:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Criminal Trials and Appeals</li>
            <li>Bail and Anticipatory Bail Matters</li>
            <li>White-collar Crimes</li>
            <li>Cybercrime Cases</li>
            <li>POCSO Act Cases</li>
            <li>Narcotics and Drugs Cases</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2 ">Strengths:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Strategic Case Preparation and Presentation</li>
            <li>Effective Argumentation and Cross-examination</li>
            <li>In-depth Knowledge of Criminal Laws and Procedures</li>
            <li>Strong Networking with Investigators and Prosecutors</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2 ">
            Court Representations:
          </h4>
          <ul className="list-disc ml-6 mb-4">
            <li>High Court of Delhi</li>
            <li>District and Session Courts</li>
          </ul>

          <p>
            Dedicated to delivering justice and protecting his clients rights,
            Advocate Gulab Singh is a trusted and reliable legal partner for
            individuals and families navigating complex criminal matters.
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <img
          src="/partnersImage/Prince bhardwaj.jpg"
          className="w-1/2 mx-auto"
        />

        <div
          className="p-6 max-w-4xl mx-auto h-full shadow-md rounded-lg bg-stone-100 font-times"
          style={{ textAlign: "justify" }}
        >
          <h2
            className="text-3xl font-bold mb-4 "
            style={{ textAlign: "center" }}
          >
            Prince Bhardwaj
          </h2>
          <p className="mb-4">
            Advocate Prince Bhardwaj is a seasoned criminal law expert with a
            strong reputation for delivering exceptional results in complex
            criminal cases, particularly POCSO matters, before Delhi Courts.
            Enrolled with the Bar Council of Delhi in 2020, he has quickly
            established himself as a formidable advocate with a keen
            understanding of criminal laws and procedures.
          </p>

          <h4 className="text-xl font-semibold mb-2">Experience:</h4>
          <p className="mb-4">
            With more than 4 years of experience, he has honed his skills in:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Criminal Trials and Appeals</li>
            <li>POCSO Act Cases</li>
            <li>Bail and Anticipatory Bail Matters</li>
            <li>White-collar Crimes</li>
            <li>Cybercrime Cases</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2">Expertise:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Strategic Case Preparation and Presentation</li>
            <li>Effective Argumentation and Cross-examination</li>
            <li>In-depth Knowledge of Criminal Laws and Procedures</li>
            <li>Strong Networking with Investigators and Prosecutors</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2">Court Representations:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>High Court of Delhi</li>
            <li>District and Session Courts</li>
          </ul>

          <p>
            Advocate Prince Bhardwaj's commitment to justice, combined with his
            extensive experience and expertise, makes him a trusted and
            formidable advocate for clients navigating complex criminal and
            POCSO matters.
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <img src="/partnersImage/Vikas Kumar.jpg" className="w-1/2 mx-auto" />

        <div className="p-6 max-w-4xl mx-auto h-full shadow-md rounded-lg bg-stone-100 font-times">
          <h2
            className="text-3xl font-bold mb-4"
            style={{ textAlign: "center" }}
          >
            Vikas Kumar
          </h2>
          <p className="mb-4">
            Advocate Vikas Kumar is a seasoned legal professional with strong
            expertise in criminal law, civil law, and family law. Enrolled with
            the Bar Council of Delhi in 2017, he has established himself as a
            dedicated and results-driven advocate.
          </p>

          <h4 className="text-xl font-semibold mb-2">Experience:</h4>
          <p className="mb-4">
            With over 7 years of experience, Advocate Kumar has honed his skills
            in:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>Criminal Trials and Appeals</li>
            <li>Civil Litigation</li>
            <li>Family Law Matters</li>
            <li>Matrimonial Disputes</li>
            <li>Property Disputes</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2">Expertise:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Strategic Case Preparation and Presentation</li>
            <li>Effective Argumentation and Negotiation</li>
            <li>In-depth Knowledge of Legal Procedures and Statutes</li>
            <li>Strong Networking with Legal Professionals and Authorities</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2">Court Representations:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>High Court of Delhi</li>
            <li>District and Session Courts</li>
            <li>Administrative Tribunals</li>
          </ul>

          <p>
            Dedicated to delivering justice and protecting his clients rights,
            Advocate Vikas Kumar is a trusted and reliable legal partner for
            individuals and families navigating complex legal issues.
          </p>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <img src="/partnersImage/aditya dogra.jpg" className="w-1/2 mx-auto" />

        <div className="p-6 max-w-4xl mx-auto h-full shadow-md rounded-lg bg-stone-100 font-times">
          <h2
            className="text-3xl font-bold mb-4"
            style={{ textAlign: "center" }}
          >
            Aditya Dogra
          </h2>
          <p className="mb-4">
            Advocate Aditya Dogra is a seasoned legal professional with a strong
            expertise in white-collar crimes and trial matters. Based in Delhi,
            he has honed his skills through a rich and diverse experience.
          </p>
          <h4 className="text-xl font-semibold mb-2">Professional Journey:</h4>

          <ul className="list-disc ml-6 mb-4">
            <li>
              ⁠Joined the chambers of Senior Advocate Vikas Pahwa in 2015,
              laying a strong foundation in legal practice under esteemed
              guidance.
            </li>
            <li>
              Served as In-House Counsel, gaining valuable insight into
              corporate legal affairs.
            </li>
            <li>
              Worked under the guidance of RS Goswami, Member, Bar Council of
              Delhi, further refining his expertise.
            </li>
          </ul>
          <h4 className="text-xl font-semibold mb-2">Expertise:</h4>
          <ul className="list-disc ml-6 mb-4">
            <li>⁠White-collar crimes </li>
            <li>⁠Trial matters </li>
            <li>Criminal law</li>
            <li>⁠Corporate legal affairs</li>
          </ul>

          <h4 className="text-xl font-semibold mb-2">
            Advocate Dogra's strengths lie in:
          </h4>
          <ul className="list-disc ml-6 mb-4">
            <li>Strategic case preparation and presentation</li>
            <li>Effective argumentation and cross-examination</li>
            <li>⁠In-depth knowledge of criminal laws and procedures.</li>
          </ul>
          
        </div>
      </div>
    </div>
  );
};

export default Partners;

