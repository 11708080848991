


import React, { useState } from "react";
import { Link } from "react-router-dom";
import { linkPath } from "../utils/constant";

const Nav = () => {
  const [isAboutHovered, setIsAboutHovered] = useState(false);

  const handleClick = () => setIsAboutHovered(!isAboutHovered); 
  const handleClose =()=> setIsAboutHovered(false)

  return (
    <div className="w-full py-4  bg-zinc-700 text-white flex flex-col md:flex-row md:justify-between max-w-[1920px] font-times">
      <div className="flex flex-col md:flex-row items-center md:items-start">
        <div className="px-4 custom:px-1 font-bold text-xl custom:text-base textsize:text-sm font-serif text-center md:text-left ">
          G&P LEGAL SOLUTIONS{" "}
          <span className="font-thin mt-2 md:mt-0 text-center textsize:text-[0.7rem] md:text-left custom:text-sm custom:py-1 italic">
            (solutions, not just advice)
          </span>
          <div className="text-xs">Since 2002</div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:w-5/12 justify-centere  md:justify-evenly mt-4 md:mt-0 ">
        <Link
          to="/home"
          className="px-4 py-2 custom:px-2 custom:text-sm customMargin:border customMargin:border-solid customMargin:border-white mb-2 md:mb-0 text-center cursor-pointer hover:text-amber-300 hover:text-xl"
          onClick={handleClose}
        >
          Home
        </Link>
        <div className="relative">
          <div
            className="px-4 py-2 custom:px-2 custom:text-sm customMargin:border customMargin:border-solid customMargin:border-white mb-2 md:mb-0 text-center cursor-pointer hover:text-amber-300 hover:text-xl"
            onClick={handleClick}
          >
            About
          </div>
          {isAboutHovered && (
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-50">
              {linkPath.map((path, idx) => {
                return (
                  <Link
                    key={idx}
                    to={path}
                    className="block px-4 py-2 hover:bg-gray-200"
                    onClick={handleClick}
                  >
                    {path.split("/").pop()}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
        <Link
          to="/services"
          className="px-4 py-2 custom:px-1 custom:text-[0.85rem] customMargin:border customMargin:border-solid customMargin:border-white text-center mb-2 md:mb-0 hover:text-amber-300 hover:text-xl"
          onClick={handleClose}
        >
          Services
        </Link>
        <Link
          to="/connect"
          className="px-4 py-2 custom:px-1 custom:text-[0.85rem] customMargin:border customMargin:border-solid customMargin:border-white text-center hover:text-amber-300 hover:text-xl"
          onClick={handleClose}
        >
          Connect
        </Link>
      </div>
    </div>
  );
};

export default Nav;



