import React from "react";

const AboutExpertise = () => {
  return (
    <div className="p-6  max-w-6xl mx-auto mt-8 px-4 text-gray-800 bg-stone-100 font-times">
      <h1 className="text-2xl font-bold mb-4">About G&P Legal Solutions</h1>
      <p className="mb-4" style={{ textAlign: "justify" }}>
        G&P Legal Solutions, a reputed law firm, was established in the year
        2002 with a vision to provide comprehensive legal services to
        individuals, businesses, and organizations. With over two decades of
        experience, our team of skilled advocates and legal professionals has
        been delivering expert legal guidance and representation across various
        courts and forums.
      </p>
      <p className="mb-4">
        Our firm has expertise in providing the following services with great
        client satisfaction:
      </p>
      <ul className="list-disc list-inside space-y-2 mb-4">
        <li>Civil Litigation</li>
        <li>Criminal Litigation</li>
        <li>Commercial Litigation</li>
        <li>Property Disputes</li>
        <li>Family Law</li>
        <li>Matrimonial Disputes</li>
        <li>Co-operative Society Disputes</li>
        <li>Contract Drafting</li>
        <li>Legal Advisory</li>
        <li>Arbitration</li>
        <li>Writ Petitions</li>
        <li>Taxation</li>
        <li>Land Acquisition</li>
        <li>Intellectual Property Rights</li>
        <li>Consumer Cases</li>
        <li>NRI Legal Services</li>
        <li>Pro Bono Legal Services (Free Legal Aid to the Poor and Needy)</li>
      </ul>
      <p className="mb-4">Our team of experienced Advocates, Led by:</p>
      <ul className="list-disc list-inside space-y-2 mb-4">
        <li>
          <b>Pramod Kumar Tripathi (Founding Partner):</b>
          <br /> Expertise in Civil Law, Property Law, Commercial Law, and Writ
          Jurisdiction.
        </li>
        <li>
          <b>Vikas Kumar (Partner):</b> <br />
          Expertise in Criminal Litigation, Bail, Trial, and Appeal matters.
        </li>
        <li>
          <b>Prince Bhardwaj (Partner): </b>
          <br />
          Expertise in Civil Disputes, Co-operative Society Disputes, and
          Contract Drafting.
        </li>
        <li>
          <b> Gulab Singh (Partner):</b> <br />
          Expertise in Matrimonial Disputes, Family Law, and Criminal Law, with
          a wide range of experience in appearing before the Criminal Division
          of the Delhi High Court.
        </li>
      </ul>
      <p className="mb-4" style={{ textAlign: "justify" }}>
        As a commitment to our social responsibility, we offer Pro Bono Legal
        Services to the poor and needy, ensuring that everyone has access to
        justice, regardless of their financial situation. Our team provides free
        legal aid, advice, and representation to those who cannot afford legal
        services, because we believe that justice should be for all, not just
        for those who can afford it.
      </p>
      <p className="mb-4" style={{ textAlign: "justify" }}>
        Together, our team provides comprehensive legal services to our clients,
        ensuring that all their legal needs are met under one roof. With a
        network spread across Delhi, our firm has a strong presence in all
        corners of the city, making us easily accessible to clients from all
        over Delhi. Client satisfaction is the topmost priority of our firm, and
        we strive to provide personalized attention, prompt service, and sound
        legal advice to ensure that our clients' legal needs are met with the
        highest level of expertise and dedication.
      </p>
    </div>
  );
};

export default AboutExpertise;
